









































import Vue from 'vue';
import SygniSteps, { SygniStepSchema } from '@/components/layout/SygniSteps.vue';
import Component from 'vue-class-component'
import SygniLine from '@/components/layout/SygniLine.vue';
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import SygniSelect from '@/components/inputs/SygniSelect.vue';
import SygniMultiSelect from '@/components/inputs/SygniMultiSelect.vue';
import SygniModal from '@/components/layout/SygniModal.vue';
import LandingPageModal from '../../../components/LandingPagePreviewModal.vue';
import SygniLoader from '@/components/layout/SygniLoader.vue';
import SygniFormBar from '@/components/layout/SygniFormBar.vue';
import { SelectOption } from '@/store/types';
import { Contexts } from '@/modules/genprox/models/User';
import { minLength, minValue, required } from 'vuelidate/lib/validators';
import { TimeTypes } from '../../../store/types';

@Component({
  components: { SygniSteps, SygniLine, SygniRectButton, SygniSelect, SygniMultiSelect, SygniModal, LandingPageModal, SygniLoader, SygniFormBar },
})
export default class AddNewCampaignModule extends Vue {
  isLoading: boolean = false;
  isLandingPageModalLoading: boolean = false;
  showLandingPageModal: boolean = false;
  showLangModal: boolean = false;

  languages: {
    activeLanguages: SelectOption<string>[],
    tempLanguages: string[],
    selectedLanguage: string,
    tempLang: string,
  } = {
    activeLanguages: [{ label: 'PL', value: 'PL' }],
    tempLanguages: [],
    tempLang: '',
    selectedLanguage: 'PL',
  }

  get hasId() {
    return this.$route?.params?.id ? true : false;
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get isMarketplaceAccountant() {
    return this.activeUserData?.role?.marketplace === 'accountant' ? true : false;
  }

  get campaignData() {
    return this.$store.getters['campaigns/getCampaignData']
  }

  get isActiveCampaign() {
    return !!(this.campaignData?.status?.toLowerCase() === 'active')
  }

  get saveCampaignTooltipText() {
    return this.isMarketplaceAccountant ? '' : 'Only draft campaigns can be updated'
  }

  get formSteps(): SygniStepSchema[] {
    const parentLink = this.$route.path.includes('company') ? 'company' : 'fund';
    return [
      {
        id: 1,
        label: 'General information',
        path: this.hasId ? `/${parentLink}/fundraising/campaign/${this.$route.params.id}/form/step-one` : `/${parentLink}/fundraising/new-campaign/form/step-one`,
      },
      {
        id: 2,
        label: 'Campaign details',
        path: this.hasId ? `/${parentLink}/fundraising/campaign/${this.$route.params.id}/form/step-two` : `/${parentLink}/fundraising/new-campaign/form/step-two`,
      },
      {
        id: 3,
        label: 'Campaign documents',
        path: this.hasId ? `/${parentLink}/fundraising/campaign/${this.$route.params.id}/form/step-three` : `/${parentLink}/fundraising/new-campaign/form/step-three`,
      },
      {
        id: 4,
        label: 'Campaign information',
        path: this.hasId ? `/${parentLink}/fundraising/campaign/${this.$route.params.id}/form/step-four` : `/${parentLink}/fundraising/new-campaign/form/step-four`,
      },
    ]
  }

  get languageOptions(): SelectOption<string>[] {
    return this.$store.getters['dictionary/globalSelects']?.countries ? this.$store.getters['dictionary/globalSelects'].countries.map((el: SelectOption<string>) => {
      return { label: el.value, value: el.value }
    }).sort((a: SelectOption<string>, b: SelectOption<string>) => {
      if(a.value < b.value) {
        return -1;
      }

      if(a.value > b.value) {
        return 1;
      }

      return 0;
    }) : [];
  }

  get currentStepId() {
    if (this.$route.name === 'newCampaignFormStepTwo' || this.$route.name === 'editCampaignFormStepTwo') return 2;

    if(this.$route.name === 'newCampaignFormStepThree' || this.$route.name === 'editCampaignFormStepThree') return 3;

    if(this.$route.name === 'newCampaignFormStepFour' || this.$route.name === 'editCampaignFormStepFour') return 4;

    return 1;
  }

  setPreviousStep() {
    const previousStep = this.currentStepId > 1 ? this.currentStepId - 1 : this.currentStepId;
    const step = this.formSteps?.find((el: any) => el?.id === previousStep)
    if (this.currentStepId !== previousStep && step?.path) {
      this.$router.push(step?.path)
    }
  }

  setNextStep() {
    const nextStep = this.currentStepId < this.formSteps?.length ? this.currentStepId + 1 : this.currentStepId
    const step = this.formSteps?.find((el: any) => el?.id === nextStep)
    if (this.currentStepId !== nextStep && step?.path) {
      this.$router.push(step?.path)
    }
  }

  scrollToError(): void {
    const error: HTMLDivElement = this.$root.$el.querySelector('.app-content .error') as HTMLDivElement | undefined;
    window?.scrollTo({ top: error.getBoundingClientRect().top - (this.$root.$el.querySelector('.add-new-campaign__actions') as HTMLElement)?.offsetHeight - 20, behavior: 'smooth' });
  }

  async activateCampaign() {
    this.$v?.$touch();

    const validationDetails: any = {
      'editCampaignFormStepOne': ['campaignName', 'landingPageUrl'],
      'editCampaignFormStepTwo': ['investmentValue', 'startDate'],
      'editCampaignFormStepFour': ['products']
    };
    
    for (const el of Array.from(Object.keys(validationDetails))) {
      let hasError = false;
      validationDetails[el].forEach((key: any) => {
        if (this.$v?.campaignData[key]?.$error) {
          hasError = true;
        }
      })

      const parentLink = this.$route.path?.includes('company') ? 'company' : 'fund';

      if (hasError) {
        if(this.$route.name !== el) {
          let path = '';

          switch(el) {
            case('editCampaignFormStepOne'):
              path = `/${parentLink}/fundraising/campaign/${this.$route.params.id}/form/step-one`;
              break;
            case('editCampaignFormStepTwo'):
              path = `/${parentLink}/fundraising/campaign/${this.$route.params.id}/form/step-two`;
              break;
            case('editCampaignFormStepThree'):
              path = `/${parentLink}/fundraising/campaign/${this.$route.params.id}/form/step-three`;
              break;
            case('editCampaignFormStepFour'):
              path = `/${parentLink}/fundraising/campaign/${this.$route.params.id}/form/step-four`;
              break;
          }

          this.$router.push({ path });
        }

        return;
      }
    }
    
    this.isLoading = true

    try {
      await this.$store.dispatch('campaigns/putCampaign', { campaignId: this.$route?.params?.id, campaignData: this.campaignData });
      await this.$store.dispatch('campaigns/markCampaignAsActive', this.$route?.params?.id);
      await this.$router.push(`/${this.$route.path.includes('company') ? 'company' : 'fund'}/fundraising?page=campaigns`);
      this.$store.commit('campaigns/clearCampaignData');
      this.$notify({
        type: 'success',
        title: 'Success',
        text: 'Campaign activated'
      })
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
    }
    
    this.isLoading = false
    return false;
  }

  async saveCampaign() {
    if (this.campaignData?.status?.toLowerCase() === 'active' || this.isMarketplaceAccountant) return
    this.$v?.campaignData?.campaignName?.$touch();

    if(this.$v?.campaignData?.campaignName?.$error) {
      if(!(this.$route.name === 'newCampaignFormStepOne' || this.$route.name === 'editCampaignFormStepOne')) {
        await this.$router.push({ name: this.hasId ? 'editCampaignFormStepOne' : 'newCampaignFormStepOne' });
      }
      return false;
    }

    this.isLoading = true;
    try {
      const action = this.hasId ? 'campaigns/putCampaign' : 'campaigns/postCampaign';
      const payload = this.hasId ? {
        campaignId: this.$route?.params?.id,
        campaignData: this.campaignData,
      } : this.campaignData;
      const campaignId = await this.$store.dispatch(action, payload);

      if (!this.hasId) {
        let stepName = 'step-one'

        if (this.currentStepId === 2) stepName = 'step-two'
        if (this.currentStepId === 3) stepName = 'step-three'
        if (this.currentStepId === 4) stepName = 'step-four'

        this.$store.commit('campaigns/clearCampaignData');
        this.$router.push(`/${this.$route?.path?.includes('company') ? 'company' : 'fund'}/fundraising/campaign/${campaignId}/form/${stepName}`)
      }
      
      setTimeout(() => {
        this.$notify({
          type: 'success',
          title: 'Success',
          text: 'Campaign saved'
        })
      }, 50)
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
    }
    this.isLoading = false;
  }

  openLangModal() {
    this.languages.tempLanguages = this.languages.activeLanguages.map((el: SelectOption<string>) => el.value);

    this.showLangModal = true;
  }

  saveLanguages() {
    if(!this.languages.tempLanguages.length) {
      this.$notify({
        type: 'error',
        title: 'Error',
        text: 'At least one language must be selected.',
        duration: 2500,
      });
      return;
    }

    if(!this.languages.tempLanguages.includes(this.languages.selectedLanguage)) {
      if(this.languages.tempLanguages.includes('PL')) {
        this.languages.selectedLanguage = 'PL';
      } else {
        this.languages.selectedLanguage = this.languages.tempLanguages[0];
      }
    }

    this.languages.activeLanguages = this.languages.tempLanguages.map((el: string) => {
      return { label: el, value: el };
    });

    this.closeLangModal();
  }

  closeLangModal() {
    this.showLangModal = false;
    this.languages.tempLanguages = [];
  }

  openLandingPageModal() {
    this.showLandingPageModal = true;
    
    setTimeout(() => {
      this.isLandingPageModalLoading = false;
    }, 600);
  }
  
  closeLandingPageModal() {
    this.isLandingPageModalLoading = false;
    this.showLandingPageModal = false;
  }

  async beforeMount() {
    if(!this.hasId) this.$store.commit('campaigns/clearCampaignData');
    await this.$store.dispatch('genprox/getIssuers');
    if (this.activeUserData.context.context !== Contexts.privateInvestor &&
      this.activeUserData.context.context !== Contexts.fundManagerPrivate) {
      try {
        const walletAddress = await this.$store.dispatch('genprox/getContextMarketplaceWalletNumber');
        this.$store.commit('genprox/setActiveContextMarketplaceWallet', walletAddress);
      } catch (err) {
        console.log(err);
      }
    }
  }

  validations() {
    const validation: any = {
      campaignData: {
        issuerId: {},
        campaignName: { required },
        landingPageUrl: { required },
        investmentValue: { required, minValue: minValue(1) },
        startDate: { required },
        endDate: {
          minValue: (value: any) => {
            return new Date(value) > new Date();
          }
        },
        products: {
          required,
          minLength: minLength(1),
          $each: {
            name: { required },
            agreementTemplateId: { required },
            data: {
              interestType: { required },
              debtor: {
                account: { required }
              },
              interestCalculationMethod: { required },
            }
          }
        }
      }
    };

    const fixedReplacementDay = this.campaignData?.products[0]?.data?.timeType === TimeTypes.fixedRepaymentDate ? true : false;

    if (fixedReplacementDay) {
      validation.campaignData.products.$each.data.fixedRepaymentDate = { required };
    } else {
      validation.campaignData.products.$each.data.duration = {
        type: { required },
        count: { required }
      }
    }

    if (this.campaignData?.products[0]?.data?.enabledAdvancedOptions) {
      validation.campaignData.products.$each.data.advancedOptions = {
        commission: { required },
        margin: { required },
        marginPayment: { required },
        commissionType: { required },
        commissionPayment: { required },
      }
    } else {
      validation.campaignData.products.$each.data.simpleOptions = {
        interest: { required },
        interestPayment: { required },
      }
    }


    return validation;
  }
}
