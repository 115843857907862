var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('portal', {
    attrs: {
      "to": "sygniFormBar"
    }
  }, [_c('div', {
    staticClass: "sygni-form-bar"
  }, [_c('div', {
    staticClass: "sygni-form-bar__inner"
  }, [_c('div', {
    staticClass: "sygni-form-bar__container"
  }, [_c('div', {
    staticClass: "sygni-form-bar__left-col"
  }, [_vm._t("actionsLeft")], 2), _c('div', {
    staticClass: "sygni-form-bar__right-col"
  }, [_vm._t("actionsRight")], 2)])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }