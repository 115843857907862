var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sygni-steps"
  }, _vm._l(_vm.steps, function (step) {
    return _c('div', {
      key: step.id,
      class: ['sygni-step', _vm.getStepAdditionalClassName(step.id), step.path ? 'clickable' : ''],
      on: {
        "click": function click($event) {
          return _vm.handleStep(step);
        }
      }
    }, [_c('div', {
      staticClass: "sygni-step__number"
    }, [_c('span', {
      staticClass: "sygni-step__number-value"
    }, [_vm._v(_vm._s(step.id))])]), _c('p', {
      staticClass: "sygni-step__label"
    }, [_vm._v(_vm._s(step.label))])]);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }