import { render, staticRenderFns } from "./SygniFormBar.vue?vue&type=template&id=4037d403&scoped=true&"
import script from "./SygniFormBar.vue?vue&type=script&lang=ts&"
export * from "./SygniFormBar.vue?vue&type=script&lang=ts&"
import style0 from "./SygniFormBar.vue?vue&type=style&index=0&id=4037d403&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4037d403",
  null
  
)

export default component.exports