











import Vue from 'vue';
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator';

export interface SygniStepSchema {
  id: number,
  label: string,
  path: string | null,
}

@Component({})
export default class SygniSteps extends Vue {
  @Prop() currentStep: number;
  @Prop() steps: SygniStepSchema[];

  getStepAdditionalClassName(stepNumber: number): string {
    if(this.currentStep > stepNumber) return 'completed';

    if(this.currentStep == stepNumber) return 'active';

    return '';
  }

  handleStep(step: SygniStepSchema) {
    if(step?.path) {
      this.$router.push({ path: step.path });
    }
  }
}
